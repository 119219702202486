export const getDataTableSizeStyles = (size: 'sm' | 'md' | 'lg') => {
  const baseStyles = {
    sm: {
      row: 'h-10',
      cell: 'px-2 py-1',
      header: 'px-2 py-1',
    },
    md: {
      row: 'h-14',
      cell: 'px-4 py-2',
      header: 'px-4 py-2',
    },
    lg: {
      row: 'h-16',
      cell: 'px-6 py-3',
      header: 'px-6 py-3',
    },
  };
  return baseStyles[size];
};
