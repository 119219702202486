import { PillVariant } from './pill.types';

export const getVariantStyles = (variant: PillVariant = PillVariant.Info) => {
  const styles = {
    [PillVariant.Success]: 'bg-SupportSuccessLight text-SupportSuccessDark',
    [PillVariant.Warning]: 'bg-SupportWarningLight text-SupportWarningDark',
    [PillVariant.Error]: 'bg-SupportErrorLight text-SupportErrorDark',
    [PillVariant.Info]: 'bg-HighlightLightest text-HighlightDarkest',
  };

  return styles[variant];
};

export const baseStyles = 'flex w-fit items-center gap-2 rounded-full px-3 py-1.5 text-[10px] font-semibold uppercase';
export const collapsedStyles = 'p-2 aspect-square';
export const iconStyles = 'h-3.5 w-3.5';
export const iconContainerStyles = 'flex items-center justify-center';
