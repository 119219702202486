import React from 'react';

import { cn } from '@/utils/styles';

import { baseStyles, collapsedStyles, getVariantStyles, iconContainerStyles, iconStyles } from './pill.core';
import type { PillProps } from './pill.types';
import { PillVariant } from './pill.types';

export const Pill = ({ text, icon, variant = PillVariant.Info, collapsed = false }: PillProps) => (
  <div className={cn(baseStyles, getVariantStyles(variant), collapsed && collapsedStyles)}>
    {icon && <span className={cn(iconContainerStyles, iconStyles)}>{icon}</span>}
    {!collapsed && <span>{text}</span>}
  </div>
);
