export enum IconContainerVariant {
  LIGHT = 'light',
  MEDIUM = 'medium',
}

export type IconProps = React.SVGProps<SVGSVGElement>;

export interface IconsType {
  Laptop: (props?: IconProps) => JSX.Element;
  CheckCircle: (props?: IconProps) => JSX.Element;
  Close: (props?: IconProps) => JSX.Element;
  Error: (props?: IconProps) => JSX.Element;
  ExclamationCircle: (props?: IconProps) => JSX.Element;
  Warning: (props?: IconProps) => JSX.Element;
  Success: (props?: IconProps) => JSX.Element;
  Info: (props?: IconProps) => JSX.Element;
  Prohibit: (props?: IconProps) => JSX.Element;
  Alert: (props?: IconProps) => JSX.Element;
  Disconnect: (props?: IconProps) => JSX.Element;
  MagicWand: (props?: IconProps) => JSX.Element;
  Sparkle: (props?: IconProps) => JSX.Element;
  GitBranch: (props?: IconProps) => JSX.Element;
  Compact: (props?: IconProps) => JSX.Element;
  BurgerMenu: (props?: IconProps) => JSX.Element;
  Search: (props?: IconProps) => JSX.Element;
  Lightning: (props?: IconProps) => JSX.Element;
  LightningOutline: (props?: IconProps) => JSX.Element;
  Path: (props?: IconProps) => JSX.Element;
  Plus: (props?: IconProps) => JSX.Element;
  Wait: (props?: IconProps) => JSX.Element;
  ClockCounterClockwise: (props?: IconProps) => JSX.Element;
}
