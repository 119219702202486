import type { HTMLAttributes } from 'react';
import React from 'react';

import type { TooltipProps } from '../tooltip';

export enum AppIconSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

export enum AppIconColourVariant {
  DEFAULT = 'default',
  LIGHT = 'light',
}

export interface AppIconContainerProps extends HTMLAttributes<HTMLDivElement> {
  size?: AppIconSize;
  colourVariant?: AppIconColourVariant;
  isPlaceholder?: boolean;
  isCompact?: boolean;
  withBorder?: boolean;
}

export enum AppIconVariant {
  APP = 'app',
  PLACEHOLDER = 'placeholder',
  COUNT = 'count',
}

export interface AppIconPlaceholderProps {
  size?: AppIconSize;
  colourVariant?: AppIconColourVariant;
  isCompact?: boolean;
  withBorder?: boolean;
}

export interface AppIconCountProps {
  count: number;
  tooltip?: TooltipProps;
  size?: AppIconSize;
  colourVariant?: AppIconColourVariant;
  isCompact?: boolean;
  withBorder?: boolean;
}

export interface AppIconDefaultProps {
  icon: {
    src: string;
    alt: string;
    width?: number;
    height?: number;
  };
  size?: AppIconSize;
  colourVariant?: AppIconColourVariant;
  isCompact?: boolean;
  withBorder?: boolean;
}

export type AppIconProps = (AppIconDefaultProps | AppIconPlaceholderProps | AppIconCountProps) & {
  variant?: AppIconVariant;
  isCompact?: boolean;
  withBorder?: boolean;
};

export interface AppIconGroupProps {
  children: React.ReactNode;
}
