import Image from 'next/image';
import React from 'react';

import type { AppIconDefaultProps } from '../app-icon.types';
import { AppIconContainer } from './app-icon-container.component';

export const AppIconDefault: React.FC<AppIconDefaultProps> = (props) => {
  const {
    size,
    colourVariant,
    isCompact,
    withBorder,
    icon: { src, alt, width = 24, height = 24 },
  } = props;

  return (
    <AppIconContainer size={size} colourVariant={colourVariant} isCompact={isCompact} withBorder={withBorder}>
      <Image width={width} height={height} className='object-cover' src={src} alt={alt} />
    </AppIconContainer>
  );
};
