import { ArrowLeftOnRectangleIcon, CreditCardIcon, UserIcon } from '@heroicons/react/24/outline';

import { URLs } from '@/utils/urls';

import { Icons } from '../../ui-library';
import type { MainNavItemType, UserMenuItemType } from './types';

export const MAIN_NAV: MainNavItemType[] = [
  {
    name: 'Flows',
    href: URLs.Flows_Overview().pathname,
    icon: <Icons.GitBranch />,
    current: false,
  },
  {
    name: 'AI Blocks',
    href: URLs.AiBlocks_List().pathname,
    icon: <Icons.Sparkle />,
    current: false,
  },
  {
    name: 'Activity',
    href: URLs.Activity().pathname,
    icon: <Icons.ClockCounterClockwise />,
    current: false,
  },
];

export const USER_MENU: UserMenuItemType[] = [
  {
    name: 'Workspace settings',
    href: URLs.ManageAccount().pathname,
    icon: <UserIcon className='w-4 h-4' />,
  },
  {
    name: 'Manage plan',
    href: URLs.Plans().pathname,
    icon: <CreditCardIcon className='w-4 h-4' />,
  },
  {
    name: 'Sign out',
    href: URLs.Logout().pathname,
    icon: <ArrowLeftOnRectangleIcon className='w-4 h-4' />,
  },
];

export const setCurrentNavItem = (pathname: string, navItems: MainNavItemType[]) => {
  return navItems.map((item) => ({
    ...item,
    current:
      (item.name === 'AI Blocks' && (pathname.includes('/details') || pathname === URLs.AiBlocks_List().pathname)) ||
      (item.name === 'Flows' && pathname.includes(URLs.Flows_Overview().pathname)) ||
      (item.name === 'Activity' && pathname.includes(URLs.Activity().pathname)),
  }));
};
