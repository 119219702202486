import React from 'react';

import { Page, PageContent, PageContentTight, Spacer } from './components';
import { PageContentWide } from './components/page-content-wide.component';
import type { LayoutType } from './layout.types';

export const Layout: LayoutType = () => {
  return <div />;
};

Layout.Spacer = Spacer;
Layout.Page = Page;
Layout.PageContent = PageContent;
Layout.PageContentTight = PageContentTight;
Layout.PageContentWide = PageContentWide;
