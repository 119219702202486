export enum FeatureFlag {
  DEFAULT = 'default',
  FLOW_GRANULAR_EXECUTION = 'flow-granular-execution',
  APP_V2_CLASSIFY_MEMORY_PAGE = 'app-v2-classify-memory-page',
  APP_V2_ENABLE_LLM_PIPELINE = 'app-v2-enable-LLM-Pipeline',
  APP_V2_UI_LIBRARY = 'app-v2-ui-library',
  GOOGLE_SHEETS_INTEGRATION = 'google-sheets-integration',
  FRONT_UPDATE_CUSTOM_FIELDS = 'front-update-conversation-custom-fields',
  DYNAMIC_OUTPUT_KEYS = 'dynamic-output-keys',
  AIRTABLE_INTEGRATION = 'airtable-integration',
  CUSTOM_AI_BLOCK = 'custom-ai-block',
  FRESHDESK_INTEGRATION = 'freshdesk-integration',
  FRESHDESK_INTEGRATION_ENPAL = 'freshdesk-integration-enpal',
  WEBHOOK_TRIGGER = 'webhook-trigger',
  CUSTOM_CODE_ACTION = 'custom-code-action',
  CUSTOM_CODE_LIVE_EDITOR = 'custom-code-live-editor',
  DUPLICATE_FLOW = 'duplicate-flow',
  CANVAS_NODE_ID = 'canvas-node-id',
  WAIT_NODE = 'wait-node',
  ATTACHMENT_EXTRACTION = 'attachment-extraction',
  OUTLOOK_INTEGRATION_V1 = 'outlook-integration-v1',
  OUTLOOK_GROUPS = 'outlook-groups',
  OUTLOOK_WITH_LINKS = 'outlook-with-links',
  FLOW_RUNS = 'flow-runs',
}

export type FeatureFlagsType = {
  [value in FeatureFlag]?: boolean | string;
};

export interface FeatureFlagReturnValue {
  flagValue: boolean | string | undefined;
  isLoading: boolean;
}

export interface FeatureFlagsContextProps {
  featureFlags: FeatureFlagsType | null;
  setFeatureFlags: (flags: FeatureFlagsType) => void;
}

export interface FeatureFlagsProviderProps {
  children: React.ReactNode;
}
