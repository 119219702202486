import React from 'react';

import { IconProps } from '../icons.types';

export const ClockCounterClockwiseIcon: React.FC<IconProps> = (props) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M9 5.625V9' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M11.9228 10.6875L9 9'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.05078 7.01123H2.23828V4.19873'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.62477 13.3752C5.4901 14.2406 6.59261 14.8299 7.79287 15.0686C8.99313 15.3074 10.2372 15.1848 11.3678 14.7165C12.4985 14.2482 13.4648 13.4551 14.1447 12.4376C14.8246 11.4201 15.1875 10.2238 15.1875 9C15.1875 7.77623 14.8246 6.57994 14.1447 5.56241C13.4648 4.54488 12.4985 3.75182 11.3678 3.2835C10.2372 2.81518 8.99313 2.69265 7.79287 2.93139C6.59261 3.17014 5.4901 3.75944 4.62477 4.62478L2.23828 7.01126'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
