export enum PillVariant {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export interface PillProps {
  text: string;
  icon?: React.ReactNode;
  variant?: PillVariant;
  collapsed?: boolean;
}
