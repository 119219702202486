import React from 'react';

import type { PageContentProps } from '../layout.types';

export const PageContentWide: React.FC<PageContentProps> = (props) => {
  const { children } = props;

  return (
    <div className='min-h-full bg-NeutralLightLight'>
      <div className='max-w-full'>{children}</div>
    </div>
  );
};
