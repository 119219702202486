import React from 'react';

import type { AppIconCountProps, AppIconDefaultProps, AppIconProps } from './app-icon.types';
import { AppIconColourVariant, AppIconSize, AppIconVariant } from './app-icon.types';
import { AppIconCount, AppIconDefault, AppIconPlaceholder } from './components';

export const AppIcon = (props: AppIconProps) => {
  const {
    variant = AppIconVariant.APP,
    size = AppIconSize.DEFAULT,
    colourVariant = AppIconColourVariant.DEFAULT,
    isCompact = false,
    withBorder = false,
  } = props;

  if (variant === AppIconVariant.PLACEHOLDER)
    return (
      <AppIconPlaceholder size={size} colourVariant={colourVariant} isCompact={isCompact} withBorder={withBorder} />
    );

  if (variant === AppIconVariant.APP)
    return (
      <AppIconDefault
        icon={(props as AppIconDefaultProps).icon}
        colourVariant={colourVariant}
        size={size}
        isCompact={isCompact}
        withBorder={withBorder}
      />
    );

  if (variant === AppIconVariant.COUNT) {
    const { count, tooltip } = props as AppIconCountProps;
    return (
      <AppIconCount
        count={count}
        colourVariant={colourVariant}
        size={size}
        tooltip={tooltip}
        isCompact={isCompact}
        withBorder={withBorder}
      />
    );
  }

  return null;
};
