import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { cn } from '@/utils/styles';

import { getDataTableSizeStyles } from './components/data-table.core';
import { DataTableProps } from './components/data-table.types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './components/table';

export function DataTable<TData, TValue>({
  columns,
  data,
  hideHeader = false,
  size = 'md',
  className,
  cellClassName,
  headerClassName,
  growingColumnId,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={cn('border-t border-b', className)}>
      <Table>
        {!hideHeader && (
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className={cn(getDataTableSizeStyles(size).header, headerClassName)}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
        )}
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={getDataTableSizeStyles(size).row}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className={cn(
                      getDataTableSizeStyles(size).cell,
                      cell.column.id === growingColumnId && 'w-full',
                      cellClassName,
                    )}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className={cn(getDataTableSizeStyles(size).row, getDataTableSizeStyles(size).cell, 'text-center')}>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
