import React from 'react';

import { cn } from '@/utils/styles';

import { getBgColor } from '../app-icon.core';
import { AppIconColourVariant, type AppIconContainerProps, AppIconSize } from '../app-icon.types';

export const AppIconContainer: React.FC<AppIconContainerProps> = ({
  isPlaceholder = false,
  isCompact = false,
  withBorder = false,
  colourVariant = AppIconColourVariant.DEFAULT,
  children,
  className,
  size = AppIconSize.DEFAULT,
  ...rest
}) => {
  const sizes = {
    [AppIconSize.SMALL]: {
      compact: 'h-7 w-7',
      default: 'h-8 w-8',
    },
    [AppIconSize.DEFAULT]: {
      compact: 'h-8 w-8',
      default: 'h-10 w-10',
    },
  };

  const sizeStyles = sizes[size][isCompact ? 'compact' : 'default'];
  const paddingStyles = isCompact ? 'p-1' : 'p-2';
  const bgStyles = getBgColor(colourVariant, isPlaceholder);

  const borderStyleMap = {
    placeholder: 'border border-dashed border-[#B7B8ED]',
    withBorder: 'border border-NeutralLightMedium',
    none: '',
  };

  const borderStyles = isPlaceholder ? borderStyleMap.placeholder : borderStyleMap[withBorder ? 'withBorder' : 'none'];

  return (
    <div
      className={cn(
        'flex items-center justify-center text-lg rounded-lg',
        sizeStyles,
        paddingStyles,
        bgStyles,
        borderStyles,
        className,
      )}
      data-testid='app-icon-container'
      {...rest}>
      {children}
    </div>
  );
};
